import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const Intensivutbildning = (props) => (
  <Layout>
    <Seo title='Intensivutbildning' />
    <PageHeader text='Intensivutbildning' />
    <div className='container'>
      <div className='row '>
        <div className='col-12 '>
          <div>
            <h2>Vår intensivutbildning</h2>
            <p> Vi erbjuder idag två olika upplägg på intensivutbildning.</p>
          </div>

        </div>

        <div className='container'>
          <div className='row mt-1 justify-content-start'>
            <div className='col-12 col-sm-12 col-md-5 col-lg-2 col-xl-2 p-0  text-center'>
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='individuell_intensivutbildning' className='rounded card-img-top' />
            </div>
            <div className='col-12 col-sm-12 col-md-7 col-lg-10 col-xl-10 mb-2 '>
              <h3>Individuell intensivutbildning </h3>
              <p>
                Individuell intensivutbildning innebär att man gör hela utbildningsprocessen precis som vanligt,
                skillnaden är att utbildningen är under en kortare tid, fast anpassad utifrån dina förutsättningar.
                <br /><br />
                <Link to='/utbildningar/individuell-Intensivutbildning' className='btn btn-danger'> Läs mera om individuell intensivkurs </Link>
              </p>

            </div>

          </div>

        </div>

        <div className='container'>
          <div className='row mt-1 justify-content-start'>
            <div className='col-12 col-sm-12 col-md-5 col-lg-2 col-xl-2 p-0 text-center'>
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt='individuell_intensivutbildning' className='rounded card-img-top' />
            </div>
            <div className='col-12 col-sm-12 col-md-7 col-lg-10 col-xl-10 mb-2 '>
              <h3>Intensivutbildning i 2 veckor</h3>
              <p>
                Vi planerar hela din utbildning under 2 veckor och hjälper dig med allt du behöver.
                Eftersom utbildningen kommer vara väldigt intensiv, ser vi helst att du enbart
                fokuserar dig på din personbilsutbildning under dessa veckor.
                <br /><br />
                <Link to='/utbildningar/intensivkurs-tva-veckor' className='btn btn-danger'> Läs mera om vår intensivkurs i 2 veckor </Link>
              </p>
            </div>
          </div>
        </div>

        <div className='col-12 '>
          <div>
            <h4>Viktigt att tänka på</h4>
            <p>
              Viktigt att tänka på när du planerar din intensivutbildning är att du både har tid för körlektioner
              och möjligheten att plugga teori samtidigt. På trafikskolan har vi teorikvällar som man kan gå på men det är inget krav.
              <br />Däremot ligger det mycket eget ansvar och det är viktigt att du tillgodoser dina teorikunskaper ordentligt.
              Det är alltså viktigt att du pluggar intensivt hemma.
              <br />Förutom teorikvällar erbjuder vi på trafikskolan körkortstester via elevcentralen.
              Elevcentralen fungerar som ett hjälpmedel både för dig som elev och som handledare.
              Via elevcentralen kan du se dina kommande körlektioner, registrerade körlektioner, boka/avboka lektioner,
              se din teoristatus samt hur du ligger till med din praktiska utbildning.
            </p>
          </div>

          <div>
            <h4 className='mb-1'>Boka en testlektion</h4>
            <p>
              För att du på bästa sätt ska tillgodogöra dig en intensivkurs är det bra om du har körvana sedan tidigare.
              <br />
              Vi rekommenderar att du bokar en testlektion, då blir det lättare att anpassa och bedömma din utbildning.
            </p>
          </div>
        </div>

      </div>
    </div>
  </Layout>
)

export default Intensivutbildning

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "utbildningar/individuell_intensivutbildning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageTwo: file(relativePath: { eq: "utbildningar/intensivutbildning_2_veckor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
} 
`